<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <platform-logo />
      <h2 class="brand-text text-primary ml-1">
        {{ platformName() }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('errors.not_authorized.title') }} 🔐
        </h2>
        <p class="mb-2">
          {{ $t('errors.not_authorized.text') }}
        </p>
        <b-button
          variant="primary"
          class="mx-1 mb-1 btn-sm-block"
          :to="loginRoute()"
        >{{ $t('Back to Home') }}</b-button>
        <b-button
          v-if="isUserLoggedIn()"
          variant="danger"
          class="mx-1 mb-1 btn-sm-block"
          @click="logout"
        >{{ $t('Logout') }}</b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import PlatformLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import {getHomeRouteForLoggedInUser, isUserLoggedIn} from '@/auth/utils'
import useJwt from "@/auth/jwt/useJwt";
import {platformName} from "../../../@core/utils/utils";

export default {
  components: {
    BLink, BImg, BButton, PlatformLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    platformName,
    isUserLoggedIn,
    loginRoute() {
      return getHomeRouteForLoggedInUser(isUserLoggedIn() ? 'admin' : null)
    },
    logout() {
      useJwt.logoutAndRevokeToken().then(() => {
        useJwt.redirectToLoginPage()
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
